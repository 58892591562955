<template>
  <div>
  <TransactionList/>
  </div>
</template>

<script>
import TransactionList from '@/components/transactions/transaction-list/TransactionList.vue';
export default {
  components: {
    TransactionList,
  },
};
</script>

<style></style>
